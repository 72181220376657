import React from 'react';
import {CNavGroup, CNavItem} from '@coreui/react';

import {getPathByName} from "./routes";
import {useLocalStorage} from "./components/AppHeader";

export const GetCount = () => {
    const messagesCount = useLocalStorage();
    return (
        messagesCount === 0 ? null : <span>{messagesCount}</span>
    );
};

export const nav = [
    {
        name: 'Home',
        to: '/',
        // get to() {return getPathByName(this.name)},
    },
    {
        name: 'ES management',
        get to() {
            return getPathByName(this.items[0].name)
        },
        permissions: [],
        items: [
            {
                component: CNavItem,
                name: 'ES feed',
                to: '/es-feed',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-bars" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'ES base',
                to: '/es-base',
                icon: <i className="fa fa-table" aria-hidden="true"></i>,
                // icon: <i className="fa fa-table nav-icon" aria-hidden="true"> </i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'ES map',
                to: '/es-map',
                // icon: <i className="fa fa-map-marker nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-map-marker" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'Es processed',
                to: '/es-base-drafts',
                // icon: <i className="fa fa-map-marker nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-pencil-square-o" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'New ES report',
                to: '/es-report/create',
                // icon: <i className="fa fa-plus nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-plus" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'Public ES reports',
                to: '/es-public-reports/reports',
                // icon: <i className="fa fa-list-alt nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-comment" aria-hidden="true"><p className="messages-count" style={{
                    margin: '5px 0 0 0',
                    padding: 0,
                    fontSize: '0.9rem'
                }}><GetCount/></p></i>,
                permissions: [],
            },
            // {
            //     component: CNavGroup,
            //     name: 'ES daily reports',
            //     // icon: <i className="fa fa-file-text-o nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-file-text-o" aria-hidden="true"></i>,
            //     permissions: [],
            //     roles: ['superuser', 'staff'],
            //     items: [
            //         {
            //             component: CNavItem,
            //             name: 'List of ES daily reports',
            //             to: '/es-daily-reports/list',
            //             // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
            //             icon: <i className="fa fa-list" aria-hidden="true"></i>,
            //             permissions: [],
            //         },
            //         {
            //             component: CNavItem,
            //             name: 'New ES daily report',
            //             to: '/es-daily-reports/new',
            //             // icon: <i className="fa fa-plus nav-icon" aria-hidden="true"> </i>,
            //             icon: <i className="fa fa-plus-square-o" aria-hidden="true"></i>,
            //             permissions: [],
            //         }
            //     ]
            // },
            // {
            //     component: CNavGroup,
            //     name: 'Public ES reports',
            //     // icon: <i className="fa fa-list-alt nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-list-alt" aria-hidden="true"></i>,
            //     permissions: [],
            //     items: [
            //         {
            //             component: CNavItem,
            //             name: 'Public ES reports',
            //             to: '/es-public-reports/reports',
            //             // icon: <i className="fa fa-list-alt nav-icon" aria-hidden="true"> </i>,
            //             icon: <i className="fa fa-comment" aria-hidden="true"></i>,
            //             permissions: [],
            //         },
            //         {
            //             component: CNavItem,
            //             name: 'Rejected public ES reports',
            //             to: '/es-public-reports/rejected',
            //             // icon: <i className="fa fa-archive nav-icon" aria-hidden="true"> </i>,
            //             icon: <i className="fa fa-ban" aria-hidden="true"></i>,
            //             permissions: [],
            //         }
            //     ]
            // },
            // {
            //     component: CNavItem,
            //     name: 'Cards from 112',
            //     to: '/es-cards-from112',
            //     // icon: <i className="fa fa-bell nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-bell" aria-hidden="true"></i>,
            //     permissions: [],
            // },
            {
                component: CNavItem,
                name: 'ES general information',
                to: '/es-general',
                // icon: <i className="fa fa-list nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-list-ul" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavItem,
                name: 'ES1 form',
                to: '/es-es1-report-form',
                // icon: <i className="fa fa-file-word-o nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-file-word-o" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            }
        ]
    },
    {
        name: 'GIS',
        get to() {
            return getPathByName(this.items[0].name)
        },
        permissions: [],
        items: [
            {
                component: CNavItem,
                name: 'Geo base',
                to: '/gis-geo-base',
                // icon: <i className="fa fa-info nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-info-circle" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavGroup,
                name: 'Geo objects management',
                // icon: <i className="fa fa-cogs nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-cogs" aria-hidden="true"></i>,
                permissions: ['geo_apps.add_geocategory', 'geo_apps.change_geocategory', 'geo_apps.delete_geocategory', 'geo_apps.view_geocategory', 'geo_apps.add_geotype', 'geo_apps.change_geotype', 'geo_apps.delete_geotype', 'geo_apps.view_geotype'],
                roles: ['superuser', 'staff'],
                items: [
                    {
                        component: CNavItem,
                        name: 'List of all geo objects',
                        to: '/gis-geo-objects/list',
                        // icon: <i className="fa fa-table nav-icon" aria-hidden="true"> </i>,
                        icon: <i className="fa fa-table" aria-hidden="true"></i>,
                        permissions: [],
                        roles: ['superuser', 'staff'],
                    },
                    {
                        component: CNavItem,
                        name: 'List of all geo categories',
                        to: '/gis-geo-categories/list',
                        // icon: <i className="fa fa-table nav-icon" aria-hidden="true"> </i>,
                        icon: <i className="fa fa-table" aria-hidden="true"></i>,
                        permissions: [],
                        roles: ['superuser', 'staff'],
                    },
                    {
                        component: CNavItem,
                        name: 'List of all types of geo objects',
                        to: '/gis-geo-types/list',
                        // icon: <i className="fa fa-table nav-icon" aria-hidden="true"> </i>,
                        icon: <i className="fa fa-table" aria-hidden="true"></i>,
                        permissions: [],
                        roles: ['superuser', 'staff'],
                    },
                ]
            },
            {
                component: CNavItem,
                name: 'Create Geo Object',
                to: '/gis-geo-object',
                // icon: <i className="fa fa-table nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-table" aria-hidden="true"></i>,
                permissions: [],
            },
            // {
            //     component: CNavItem,
            //     name: 'Import',
            //     to: '/gis-objects-import',
            //     // icon: <i className="fa fa-share-square nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-upload" aria-hidden="true"></i>,
            //     permissions: [],
            //     roles: ['superuser', 'staff'],
            // },
            // {
            //     component: CNavItem,
            //     name: 'Export',
            //     to: '/gis-objects-export',
            //     // icon: <i className="fa fa-download nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-download" aria-hidden="true"></i>,
            //     permissions: [],
            //     roles: ['superuser', 'staff'],
            // }
        ]
    },
    {
        name: 'Assignments',
        // get to() {return getPathByName(this.items[0].name)},
        to: '/assignments/assignments-main',
        permissions: [],
        items: [
            {
                component: CNavItem,
                name: 'Board',
                to: '/assignments/assignments-main',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-list" aria-hidden="true"></i>,
                className: 'desktop-only',
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'Implement',
                to: '/assignments/generated',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-list" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'In work',
                to: '/assignments/in-work',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-list" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'Approval',
                to: '/assignments/done',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-list" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'Completed',
                to: '/assignments/completed',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-list" aria-hidden="true"></i>,
                permissions: [],
            },
            {
                component: CNavItem,
                name: 'Create assignment',
                to: '/assignments/assignments-main/create-edit-assignment',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-plus" aria-hidden="true"></i>,
                permissions: [],
            },
        ]
    },
    // {
    //     name: 'Registry',
    //     // get to() {return getPathByName(this.items[0].name)},
    //     to: '/registry-document-types',
    //     permissions: [],
    //     items: [
    //         {
    //             component: CNavItem,
    //             name: 'List of document types',
    //             to: '/registry-document-types',
    //             // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
    //             icon: <i className="fa fa-list" aria-hidden="true"></i>,
    //             permissions: [],
    //         },
    //         {
    //             component: CNavGroup,
    //             name: 'Dictionaries',
    //             // icon: <i className="fa fa-book nav-icon" aria-hidden="true"> </i>,
    //             icon: <i className="fa fa-book" aria-hidden="true"></i>,
    //             permissions: [],
    //             items: [
    //                 {
    //                     component: CNavItem,
    //                     name: 'List of dictionaries',
    //                     to: '/registry-dictionaries/list',
    //                     // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
    //                     icon: <i className="fa fa-bars" aria-hidden="true"></i>,
    //                     permissions: [],
    //                 },
    //                 {
    //                     component: CNavItem,
    //                     name: 'New dictionary',
    //                     to: '/registry-dictionaries/new',
    //                     // icon: <i className="fa fa-plus nav-icon" aria-hidden="true"> </i>,
    //                     icon: <i className="fa fa-plus" aria-hidden="true"></i>,
    //                     permissions: [],
    //                 }
    //             ]
    //         },
    //         {
    //             component: CNavGroup,
    //             name: 'Reports',
    //             // icon: <i className="fa fa-print nav-icon" aria-hidden="true"> </i>,
    //             icon: <i className="fa fa-print" aria-hidden="true"></i>,
    //             permissions: [],
    //             items: [
    //                 {
    //                     component: CNavItem,
    //                     name: 'List of reports',
    //                     to: '/registry-reports/list',
    //                     icon: <i className="fa fa-bars" aria-hidden="true"></i>,
    //                     permissions: [],
    //                 },
    //                 {
    //                     component: CNavItem,
    //                     name: 'New report',
    //                     to: '/registry-reports/new',
    //                     icon: <i className="fa fa-plus" aria-hidden="true"></i>,
    //                     permissions: [],
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        name: 'Analytics',
        get to() {
            return getPathByName(this.items[0].name)
        },
        permissions: [],
        roles: ['superuser', 'staff'],
        items: [
            {
                component: CNavItem,
                name: 'List of analytic templates',
                to: '/analytic-templates',
                icon: <i className="fa fa-bars" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavItem,
                name: 'New analytic template',
                to: '/analytic-templates/create',
                icon: <i className="fa fa-plus" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavItem,
                name: 'Report',
                to: '/analytic/report',
                icon: <i className="fa fa-file-text-o" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            }
        ]
    },
    {
        name: 'Services',
        to: '/services',
        permissions: [],
        roles: ['superuser', 'staff'],
        items: [
            {
                component: CNavItem,
                name: 'List of services',
                to: '/services',
                // icon: <i className="fa fa-home nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-home" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavGroup,
                name: 'Available services',
                icon: <i className="fa fa-globe nav-icon" aria-hidden="true"> </i>,
                permissions: [],
                roles: ['superuser', 'staff'],
                items: []
            }
        ]
    },
    {
        name: 'Video streams',
        to: '/video-streams',
        permissions: [],
        roles: ['superuser', 'staff'],
        items: [
            {
                component: CNavItem,
                name: 'Video streams',
                to: '/video-streams',
                // icon: <i className="fa fa-bars nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-bars" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavItem,
                name: 'Channels wall',
                to: '/channels-wall',
                // icon: <i className="fa fa-film nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-film" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            // {
            //     component: CNavItem,
            //     name: 'Channels wall settings',
            //     to: '/channels-wall-settings',
            //     icon: <i className="fa fa-cogs" aria-hidden="true"></i>,
            //     permissions: [],
            // }
        ]
    },
    {
        name: 'Settings',
        to: '/es-types',
        // to: '/users/list',
        permissions: [],
        roles: ['superuser', 'staff'],
        items: [
            // {
            //     component: CNavGroup,
            //     name: 'User management',
            //     // icon: <i className="fa fa-users nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-users" aria-hidden="true"></i>,
            //     permissions: [],
            //     items: [
            //         {
            //             component: CNavItem,
            //             name: 'Users list',
            //             to: '/users/list',
            //
            //             icon: <i className="fa fa-bars" aria-hidden="true"></i>,
            //             permissions: ['accounts_apps.add_user', 'accounts_apps.change_user', 'accounts_apps.delete_user', 'accounts_apps.view_user'],
            //         },
            //         {
            //             component: CNavItem,
            //             name: 'Groups list',
            //             to: '/users/groups-list',
            //             icon: <i className="fa fa-bars" aria-hidden="true"></i>,
            //             permissions: ['accounts_apps.add_groups', 'accounts_apps.change_groups', 'accounts_apps.delete_groups', 'accounts_apps.view_groups'],
            //         }
            //     ]
            // },
            {
                component: CNavItem,
                name: 'Audit log',
                to: '/audit-log',
                // icon: <i className="fa fa-desktop nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-television" aria-hidden="true"></i>,
                permissions: ['admin.add_logentry', 'admin.change_logentry', 'admin.delete_logentry', 'admin.view_logentry'],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavItem,
                name: 'ES types',
                to: '/es-types',
                // icon: <i className="fa fa-folder-o nav-icon" aria-hidden="true"/>,
                icon: <i className="fa fa-list-ol" aria-hidden="true"></i>,
                permissions: [],
                roles: ['superuser', 'staff'],
            },
            // {
            //     component: CNavItem,
            //     name: 'User manual',
            //     to: '/user-manual',
            //     // icon: <i className="fa fa-info-circle nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-archive" aria-hidden="true"></i>,
            //     permissions: [],
            // },
            {
                component: CNavItem,
                name: 'Geo icons',
                to: '/geo-icons',
                // icon: <i className="fa fa-map-marker nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-map-marker" aria-hidden="true"></i>,
                permissions: ['geo_apps.add_geoicon', 'geo_apps.change_geoicon', 'geo_apps.delete_geoicon', 'geo_apps.view_geoicon'],
                roles: ['superuser', 'staff'],
            },
            // {
            //     component: CNavItem,
            //     name: 'Browser policy',
            //     to: '/browser-policy',
            //     // icon: <i className="fa fa-shield nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-file-text-o" aria-hidden="true"></i>,
            //     permissions: [],
            // },
            // {
            //     component: CNavItem,
            //     name: 'General settings',
            //     to: '/general-settings',
            //     // icon: <i className="fa fa-cogs nav-icon" aria-hidden="true"> </i>,
            //     icon: <i className="fa fa-cogs" aria-hidden="true"></i>,
            //     permissions: [],
            // },
            {
                component: CNavItem,
                name: 'Regions',
                to: '/regions',
                // icon: <i className="fa fa-sitemap nav-icon" aria-hidden="true"> </i>,
                icon: <i className="fa fa-map" aria-hidden="true"></i>,
                permissions: ['regions_apps.add_region', 'regions_apps.change_region', 'regions_apps.delete_region', 'regions_apps.view_region', 'regions_apps.add_district', 'regions_apps.change_district', 'regions_apps.delete_district', 'regions_apps.view_district'],
                roles: ['superuser', 'staff'],
            },
            {
                component: CNavItem,
                name: 'Version history',
                to: '/version-history',
                icon: <i className="fa fa-info-circle" aria-hidden="true"></i>,
                permissions: [],
            }
        ]
    },
    {
        name: 'Public app',
        to: '/public-mobile-admin',
        permissions: [],
        roles: ['superuser', 'staff'],
    },
    // {
    //     name: 'Earth map',
    //     to: '/earth-map',
    //     permissions: [],
    //     roles: ['superuser', 'staff'],
    // },
    // {
    //     name: 'RIS',
    //     to: '/ris',
    //     permissions: [],
    //     roles: ['superuser', 'staff'],
    // }
];
