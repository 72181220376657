import React, { useEffect, useState } from 'react';
import './Calendar.css';
import locales from "./locales";
import { getFormattedDate, rangeStartYear, rangeEndYear } from "./config";

interface CalendarProps {
  locale?: string;
  range: {
    start: Date;
    end: Date;
  };
  start?: any;
  end?: any;
  onClick: (date: Date) => void;
  norange?: boolean
}

const Calendar: React.FC<CalendarProps>  = ({ range, onClick, locale, start, end, norange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth()));
  const [calendar, setCalendar] = useState<Array<Date[]>>([[],[],[],[],[],[]]);
  const [years, setYears] = useState<Array<(number | null)[]>>([[],[],[],[],[],[],[]]);
  const [showYearsSelect, setShowYearsSelect] = useState(false);

  useEffect(() => {
    monthIncrement();
    yearsRender(new Array(rangeEndYear - rangeStartYear + 1).fill(0).map((_, idx) => idx + rangeStartYear));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const oldMonth = `${currentMonth.getFullYear()}${currentMonth.getMonth()}`;
    let month = null;
    if (start && range.start) {
      const newMonth = `${range.start.getFullYear()}${range.start.getMonth()}`;
      if (newMonth !== oldMonth) month = new Date(range.start.getFullYear(), range.start.getMonth());
    }
    if (end && range.end) {
      const newMonth = `${range.end.getFullYear()}${range.end.getMonth()}`;
      if (newMonth !== oldMonth) month = new Date(range.end.getFullYear(), range.end.getMonth());
    }
    if (month) {
      calendarRender(month);
      setCurrentMonth(month);
    }
    // eslint-disable-next-line
  }, [range.start, range.end]);

  const showYearsSelectToggle = () => {
    setShowYearsSelect(prev => !prev);
  };

  const getMonthTranslation = (n: any, full: any) => {
    const lng = locale || 'ru';
    switch (n) {
      case 0: return locales(lng,full ? 'January' : 'Jan');
      case 1: return locales(lng,full ? 'February' : 'Feb');
      case 2: return locales(lng,full ? 'March' : 'Mar');
      case 3: return locales(lng,full ? 'April' : 'Apr');
      case 4: return locales(lng,full ? 'May' : 'Ma');
      case 5: return locales(lng,full ? 'June' : 'Jun');
      case 6: return locales(lng,full ? 'July' : 'Jul');
      case 7: return locales(lng,full ? 'August' : 'Aug');
      case 8: return locales(lng,full ? 'September' : 'Sep');
      case 9: return locales(lng,full ? 'October' : 'Oct');
      case 10: return locales(lng,full ? 'November' : 'Nov');
      case 11: return locales(lng,full ? 'December' : 'Dec');
      default: return n;
    }
  };

  const yearsRender = (years: number[]) => {
    const cells: Array<(number | null)[]> = [[],[],[],[],[],[],[]];

    for (let i = 0; i < 49; i++) {
      const subIdx = Math.floor(i / 7);
      cells[subIdx].push((rangeStartYear + i > rangeEndYear) ? null : (rangeStartYear + i));
    }

    setYears(cells);
  };

  const calendarRender = (month: any) => {
    const dates: Array<Date[]> = [[],[],[],[],[],[]];
    let decrement = 1 - month.getDay();
    if (decrement > -2) decrement -= 7;

    for (let i = 0; i < 42; i++) {
      const subIdx = Math.floor(i / 7);
      dates[subIdx].push(new Date(month.getTime() + (i + decrement) * 24 * 60 * 60 * 1000));
    }

    setCalendar(dates);
  };

  const setYear = (year: number) => {
    const newMonth = new Date(currentMonth);
    newMonth.setFullYear(year);
    calendarRender(newMonth);
    setCurrentMonth(newMonth);
    showYearsSelectToggle();
  };

  const monthIncrement = (step?: number): void => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(currentMonth.getMonth() + (step || 0));
    calendarRender(newMonth);
    setCurrentMonth(newMonth);
  };

  let calendarList: any[] = [];
  try {
    const today = getFormattedDate(new Date());
    const startDay = getFormattedDate(range.start);
    const endDay = getFormattedDate(range.end);

    calendarList = calendar.map(week => (
      <tr key={'week' + Math.random()}>
        {week.map((day: any) => {
          const thisDay = getFormattedDate(day);
          let className = 'dtrp-day';
          if (currentMonth.getMonth() !== day.getMonth()) className += ' out-of-month';
          if (thisDay === today) className += ' is-today';
          if (thisDay === startDay || thisDay === endDay) className += ' day-active';
          if (thisDay > startDay && thisDay < endDay) className += ' in-range';

          return (
            <td key={'day' + Math.random()}
                className={className}
                onClick={() => onClick(day)}
            >
              {day.getDate()}
            </td>
          )
        })}
      </tr>
    ))
  } catch (e) {}

  const daysList = (
    <tr>
      <th>{locales(locale || 'en', 'mo')}</th>
      <th>{locales(locale || 'en', 'tu')}</th>
      <th>{locales(locale || 'en', 'we')}</th>
      <th>{locales(locale || 'en', 'th')}</th>
      <th>{locales(locale || 'en', 'fr')}</th>
      <th>{locales(locale || 'en', 'sa')}</th>
      <th>{locales(locale || 'en', 'su')}</th>
    </tr>
  );

  const yearsList = years.map(line => (
    <tr key={'yearLine' + Math.random()}>
      {line.map(year => year ? <th key={'year' + Math.random()}
                                   className='dtrp-day'
                                   onClick={() => setYear(year)}
          >
            {year}
          </th>
          :
          <th key={'year' + Math.random()}></th>
      )}
    </tr>
  ));

  return (
    <div className="dtrp-calendar">
      <table>
        <thead>
        <tr>
          {showYearsSelect ?
            <th className="dtrp-month-pick-arrow" colSpan={7} onClick={showYearsSelectToggle}>
              {currentMonth.getFullYear()}
            </th>
            :
            <>
              <th className="dtrp-month-pick-arrow" onClick={() => monthIncrement(-1)}>{'<'}</th>
              <th className="dtrp-month-pick-arrow" colSpan={5} onClick={showYearsSelectToggle}>
                {getMonthTranslation(currentMonth.getMonth(), true)} {currentMonth.getFullYear()}
              </th>
              <th className="dtrp-month-pick-arrow" onClick={() => monthIncrement(1)}>{'>'}</th>
            </>
          }
        </tr>
        {showYearsSelect ? yearsList : daysList}
        </thead>
        <tbody>
        {showYearsSelect ? null : calendarList}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
