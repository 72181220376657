import React from 'react';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import PriorityComponent from "../dragAndDrop/components/PriorityComponent";
import {IAssignmentsList} from "../../../interfaces/IAssignments";
import {useGetReadCurrentUserQuery} from "../../../store/account/account.api";
import {userNameFormation} from "../Util/Util";

type TaskItemProps = {
  task: IAssignmentsList;
  onClick?: () => void;
};

const TaskCardMobile = ({ task, onClick = () => {} }: TaskItemProps) => {
  const {t} = useTranslation();

  const {data: me, isLoading: meLoading} = useGetReadCurrentUserQuery();

  if (!task) return null;

  return (
    <div className="task-card-mobile" onClick={onClick}>
      <div className="task-card-mobile__title">
        <PriorityComponent priority={task.priority}/>
        <span>{task.title}</span>
      </div>
      <div className="task-card-mobile__info">
        <strong>{t('assignmentsPage.author')}: </strong>
        {userNameFormation(task.author)}
      </div>
      <div className="task-card-mobile__info">
        <strong>{t('assignmentsPage.responsibleExecutor')}: </strong>
        {userNameFormation(task.responsible_executor)}
      </div>
      <div className="task-card-mobile__info">
        <strong>{t('assignmentsPage.create_at')}</strong>
        {dayjs(task.created_at).format('DD.MM.YYYY HH:mm')}
      </div>
      <div className="task-card-mobile__info">
        <strong>{t('assignmentsPage.deadline')}</strong>
        {dayjs(task.datetime_deadline).format('DD.MM.YYYY HH:mm')}
      </div>
      <div className="task-card-mobile__description">
        {task.description}
      </div>
    </div>
  );
};

export default TaskCardMobile;
