import React, {ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import {
  CButton,
  CButtonGroup,
  CFormInput, CModal, CModalBody,
  CTable, CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import ReactHlsPlayer from "react-hls-player";

import DefaultModal from '../../../components/UI/Modals/DefaultModal';
import FormGroup from "../../../components/UI/Forms/FormGroup";

import {useTranslation} from "react-i18next";
import {
  useCreateVideoStreamMutation,
  useLazyGetVideoStreamsQuery, useRemoveVideoStreamMutation,
  useUpdateVideoStreamMutation
} from "../../../store/videoStreams/videoStreams.api";
import DefaultPlayer from "../../../components/VideoPlayers/DefaultPlayer";
import {sortArrayOfObjectsByKey} from "../../../functions";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

interface VideoStream {
  id: number;
  videowall_name: string;
  url: string;
}

function checkVideoType (source: string) {
  if (source.indexOf('://youtu') > 0) return 'youtube';
  if (source.indexOf('://www.youtu') > 0) return 'youtube';
  if (source.indexOf('://flowplayer') > 0) return 'youtube';
}
const VideoStreams = () => {
  const { t } = useTranslation();

  const playerRef = useRef(null);

  const [fetchVideoStreams, {isError, isLoading: fetchVideoStreamsLoading, isFetching: fetchVideoStreamsFetching, data: videoStreams}] = useLazyGetVideoStreamsQuery();
  const [createVideoStream, {isLoading: createVideoStreamsLoading}] = useCreateVideoStreamMutation();
  const [updateVideoStream, {isLoading: updateVideoStreamsLoading}] = useUpdateVideoStreamMutation();
  const [deleteVideoStream, {isLoading: deleteVideoStreamsLoading}] = useRemoveVideoStreamMutation();

  const [visible, setVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [state, setState]: any = useState({ videowall_name: '', url: '' });
  const [error, setError] = useState<string | null>(null);
  const [sorting, setSorting] = useState({ by: 'videowall_name', direction: 'asc' });

  useEffect(() => {
    fetchVideoStreams();
    // dispatch(fetchVideoStreams());
    // eslint-disable-next-line
  },[]);

  const isLoading = useMemo(() => {
    return (fetchVideoStreamsLoading || fetchVideoStreamsFetching || createVideoStreamsLoading || updateVideoStreamsLoading || deleteVideoStreamsLoading);
  }, [fetchVideoStreamsLoading, fetchVideoStreamsFetching, createVideoStreamsLoading, updateVideoStreamsLoading, deleteVideoStreamsLoading]);

  const sortingHandler = (field: string) => {
    const innerState = {...sorting};
    if (innerState.by === field) {
      innerState.direction = innerState.direction === 'asc' ? 'desc' : 'asc';
    }
    else {
      innerState.by = field;
      innerState.direction = 'asc';
    }
    setSorting(innerState);
  };

  const togglePreviewModalHandler = (visible: boolean, data?: VideoStream): void => {
    if (data && data.id) {
      setSelectedId(data.id);
      setState({ videowall_name: data.videowall_name, url: data.url });
    } else {
      setTimeout(() => {
        setSelectedId(null);
        setState({ videowall_name: '', url: '' });
      }, 200);
    }
    setError(null);
    setPreviewModalVisible(!!visible);
  };

  const toggleEditModalHandler = (visible: boolean, data?: VideoStream): void => {
    if (data && data.id) {
      setSelectedId(data.id);
      setState({ videowall_name: data.videowall_name, url: data.url });
    } else {
      setTimeout(() => {
        setSelectedId(null);
        setState({ videowall_name: '', url: '' });
      }, 200);
    }
    setError(null);
    setVisible(!!visible);
  };

  const toggleDeleteModalHandler = (visible: boolean, data?: VideoStream): void => {
    if (data && data.id) {
      setSelectedId(data.id);
      setState({ videowall_name: data.videowall_name, url: data.url });
    } else {
      setTimeout(() => {
        setSelectedId(null);
        setState({ videowall_name: '', url: '' });
      }, 200);
    }
    setError(null);
    setDeleteModalVisible(!!visible);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setState((prevState: any) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const onBlurUrlHandler = (url: string) => {
    // eslint-disable-next-line
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    if (url.match(regex)) {
      // nothing
    } else {
      setState((prevState: any) => ({ ...prevState, url: ''}));
    }
  };

  const handleEdit = async (): Promise<void> => {
    if (!state.videowall_name) {
      setError(`"${t('settings.geoCategories.title')}" - ${t('Should not be empty')}`);
      return;
    }
    if (!state.url) {
      setError(`"${t('URL')}" - ${t('Should not be empty')}`);
      return;
    }

    if (selectedId) {
      // await dispatch(updateVideoStream(selectedId, state));
      updateVideoStream({id: selectedId, body: state});
    } else {
      createVideoStream(state);
      // await dispatch(createVideoStream(state));
    }
    fetchVideoStreams();
    // dispatch(fetchVideoStreams());
    toggleEditModalHandler(false);
  };

  const handleDelete = async (): Promise<void> => {
    deleteVideoStream(Number(selectedId));
    // await dispatch(deleteVideoStream(selectedId));
    toggleDeleteModalHandler(false);
  };

  let servicesForTable: ReactNode;

  if (videoStreams?.length) {
    servicesForTable = sortArrayOfObjectsByKey(videoStreams, sorting.by, sorting.direction === 'desc').map((el: any) => {
      return (
        <CTableRow key={el.id}>
          <CTableDataCell style={{cursor: 'pointer'}} onClick={() => togglePreviewModalHandler(true, el)}>
            {/*eslint-disable-next-line*/}
            <a href="#" style={{textDecoration: 'none', color: '#39f'}} onClick={e => e.preventDefault()}>{el.videowall_name}</a>
          </CTableDataCell>
          <CTableDataCell>
            <div style={{wordBreak: 'break-all'}}>{el.url}</div>
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton size="sm" color="info" className="default-table__action-btn"
                       onClick={() => toggleEditModalHandler(true, el)}>
                <i className="fa fa-pencil-square-o color-white"/>
              </CButton>
              <CButton size="sm" color="danger" className="default-table__action-btn"
                       onClick={() => toggleDeleteModalHandler(true, el)}>
                <i className="fa fa-times color-white" aria-hidden="true"/>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider flex">
        <h1>{t('nav.Video streams')}</h1>
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={() => toggleEditModalHandler(true)}
        >
          {t('videoStreams.addVideoStream')}
        </CButton>
      </div>

      <div className="mb-3">
        <LoaderLine visible={isLoading}/>
      </div>

      <CTable bordered striped className="default-table">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => sortingHandler('videowall_name')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('videoStreams.name')}</span>
                {sorting.by === 'videowall_name' ?
                  <i className={`fa fa-sort-amount-${sorting.direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => sortingHandler('url')}
            >
              <div className="d-flex justify-content-between">
                <span>Url</span>
                {sorting.by === 'url' ?
                  <i className={`fa fa-sort-amount-${sorting.direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
              {t('videoStreams.actions')}
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {servicesForTable}
        </CTableBody>
      </CTable>

      <CModal className="default-modal video-stream-preview-modal"
              alignment="center"
              onClose={() => setPreviewModalVisible(false)}
              visible={previewModalVisible && !!state.url}
              size="lg"
      >
        <CModalBody className="p-3">
          {/*{checkVideoType(state.url) === 'youtube' ?*/}
          {/*  <iframe id={'video-wall__player'}*/}
          {/*          width='560'*/}
          {/*          height='315'*/}
          {/*          // src={state.url}*/}
          {/*          src={state.url + '?mute=1&disablekb=1&loop=1&autoplay=1'}*/}
          {/*          // src={state.url + '?mute=1&disablekb=1&loop=1&playlist=' + state.url.split('/').reverse()[0] + '&autoplay=1'}*/}
          {/*          title="YouTube video player"*/}
          {/*          frameBorder="0"*/}
          {/*          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
          {/*          referrerPolicy="strict-origin-when-cross-origin"*/}
          {/*          allowFullScreen*/}
          {/*  />*/}
          {/*  :*/}
          {/*  <ReactHlsPlayer playerRef={playerRef}*/}
          {/*                  src={state?.url || ''}*/}
          {/*                  autoPlay={true}*/}
          {/*                  controls={true}*/}
          {/*                  width={'100%'}*/}
          {/*                  height={'auto'}*/}
          {/*  />*/}
          {/*}*/}

          <DefaultPlayer id={'video-wall__player'}
                         source={state?.url || ''}
                         width='560px'
                         height='315px'
                         controls
                         autoPlay
                         allowManagement={true}
          />
        </CModalBody>
      </CModal>

      <DefaultModal visible={visible}
                    setVisible={toggleEditModalHandler}
                    title={selectedId ? t('videoStreams.editVideoStream') : t('videoStreams.addVideoStream')}
                    type="info"
                    cancelButton={t('cancel')}
                    onOk={handleEdit}
                    error={error}
      >
        <div className=" align-items-center mb-3 mt-3">
          <FormGroup htmlFor="typeOfGeoObject" label={t('videoStreams.name')}>
            <CFormInput id="typeOfGeoObject"
                        type="text"
                        value={state.videowall_name || ''}
                        name="videowall_name"
                        autoComplete={'off'}
                        onChange={handleChange}
                        size="sm"
            />
          </FormGroup>
          <FormGroup htmlFor="typeOfGeoObject" label={'Url'}>
            <CFormInput id="typeOfGeoObject"
                        type="text"
                        value={state.url || ''}
                        name="url"
                        autoComplete={'off'}
                        onChange={handleChange}
                        onBlur={() => onBlurUrlHandler(state.url)}
                        size="sm"
            />
          </FormGroup>
        </div>
      </DefaultModal>

      <DefaultModal visible={deleteModalVisible}
                    setVisible={toggleDeleteModalHandler}
                    type="danger"
                    title={t('videoStreams.deleteVideoStream')}
                    cancelButton={t('cancel')}
                    okButton={t('delete')}
                    onOk={handleDelete}
      >
        <p>{t('videoStreams.Do you really want to delete a video stream?')}</p>
        <p><strong>{state.videowall_name}</strong></p>
      </DefaultModal>
    </>
  );
};

export default VideoStreams;
