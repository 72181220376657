import dayjs from 'dayjs';
import { IAssignmentsList } from '../../../../interfaces/IAssignments';
import { descriptionEdit } from '../utils/tasks';
import PriorityComponent from './PriorityComponent';
import '../../../../scss/_drag_n_drop.scss';
import React from "react";
import {useTranslation} from "react-i18next";
import {userNameFormation} from "../../Util/Util";
import {useGetReadCurrentUserQuery} from "../../../../store/account/account.api";

type TaskItemProps = {
  task: IAssignmentsList;
};

const TaskItem = ({ task }: TaskItemProps) => {
  const {t} = useTranslation();

  const {data: me, isLoading: meLoading} = useGetReadCurrentUserQuery();

  if (!task) return null;

  return (
    <div className='board-block_tasks_content'>
      <div className='board-block_tasks_content_text'>
        <span className='board-block_tasks_content_text_title'>{descriptionEdit(task.title, 50)}</span>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <span className='board-block_tasks_content_info_date'>
          <b>{t('assignmentsPage.create_at')}</b>
          {dayjs(task.created_at).format('DD.MM.YYYY HH:mm')}
        </span>

        {task.datetime_deadline ?
          <span className='board-block_tasks_content_info_date'>
            <b>{t('assignmentsPage.deadline')}</b>
            {dayjs(task.datetime_deadline).format('DD.MM.YYYY HH:mm')}
          </span>
          : null}

        <span className='board-block_tasks_content_info_date'>
          <b>{t('assignmentsPage.author')}: </b>
          {task.author.id === me?.id ? t('assignmentsPage.you') : userNameFormation(task.author)}
        </span>
      </div>
      <div className='board-block_tasks_content_info'>
        <div className='board-block_tasks_content_info_author'>
          <PriorityComponent priority={task.priority}/>
          <span>{userNameFormation(task.responsible_executor) || t('assignmentsPage.Not assigned')}</span>
        </div>
      </div>
      {/*<span className='board-block_tasks_content_text_desc'>{descriptionEdit(task.description, 50)}</span>*/}
    </div>

  );
};

export default TaskItem;
