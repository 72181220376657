import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { SyntheticEvent, useState } from 'react';
import { CButton, CButtonGroup, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import {
  cutBySpaceAboveSpecificLength,
  IFilterSettings,
  sortingAndFilteringList
} from '../dragAndDrop/utils/tasks';
import {
  useChangeAssignmentStatusMutation,
  useGetAssigmentsListQuery,
  useRemoveAssigmentMutation
} from '../../../store/assignments/assignments.api';
import { IAssignmentsList } from '../../../interfaces/IAssignments';
import { useGetReadCurrentUserQuery } from '../../../store/account/account.api';
import { Statuses } from '../dragAndDrop/constants';
import DefaultModal from '../../../components/UI/Modals/DefaultModal';
import CommentsComponent from '../../../components/UI/CommentsUI/CommentsComponent/CommentsComponent';
import PriorityComponent from '../dragAndDrop/components/PriorityComponent';
import * as icon from '@coreui/icons';
import {
  chooseStatusHandler,
  navigateToPathAndIdHandler,
  openModalsAndAddTaskHandler,
  userNameFormation
} from "../Util/Util";
import SortAndFilter from "../../../components/UI/SortAndFilter/SortAndFilter";
import FileComponents from "../../../components/UI/CommentsUI/FileComponents/FileComponents";
import config from "../../../config";
import TaskCardMobile from "../components/TaskCardMobile";

const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];

const Done = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { data: tasks } = useGetAssigmentsListQuery();
  const [changeAssignmentStatus] = useChangeAssignmentStatusMutation();
  const [removeAssignment, {data: removeAssignmentRes, isLoading: removeAssignmentLoading, isError: removeAssignmentError}] = useRemoveAssigmentMutation();
  const [doneState, setDoneState] = useState<IAssignmentsList[]>([]);
  const { data: me } = useGetReadCurrentUserQuery();
  const [permission, setPermission] = useState<boolean | null>(null);
  const [visible, setVisible] = useState(false);
  const [activeTask, setActiveTask] = useState<IAssignmentsList>();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [activeIdTask, setActiveIdTask] = useState<string | number>();
  const [newStatus, setNewStatus] = useState('');
  const [remButton, setRemButton] = useState(false);
  const [filterSettings, setFilterSettings] = useState<IFilterSettings>({ sort_by: 'date', sort_direction: 'asc' });

  useEffect(() => {
    setDoneState(sortingAndFilteringList(tasks, filterSettings, me?.username, 'done'));
  }, [filterSettings, tasks]);

  const setFilterHandler = (sort_by: string) => {
    const innerState = {...filterSettings};
    if (sort_by === innerState.sort_by) innerState.sort_direction = innerState.sort_direction === 'asc' ? 'desc' : 'asc';
    else innerState.sort_direction = 'asc';
    innerState.sort_by = sort_by;
    setFilterSettings(innerState);
  };

  const changeTaskHandler = () => {
    doneState && doneState.map(task => {
      if(task.id === activeIdTask) {
        return {...task, status: newStatus}
      }
      else {
        return task;
      }
    });
    changeAssignmentStatus({id: activeIdTask, status: newStatus});
    setVisibleEdit(false);    
  };

  const getDataWithQueryHandler  = (url: string) => {
    const element = document.createElement('a');    
    element.setAttribute('href', config.baseUrl + url);
    element.setAttribute('target', '_blank');    
    element.click();   
  };

  // const removeAssignmentHandler = (id: any) => {
  //   if(activeTask?.author.id === me.id) {
  //     removeAssigment(id);
  //     setRemButton(false);
  //   }
  // };

  const removeAssignmentHandler = (id?: string | number) => {
    try {
      if (!id || isNaN(Number(id))) return null;
    } catch (e) {
      return null;
    }

    removeAssignment(Number(id)).then((res: any) => {
      if (!res.error) setRemButton(false);
    });
  };

  const approvalTasks = doneState?.map(task => {
    return (
      <CTableRow key={task.id}>
      <CTableDataCell>
        {dayjs(task.created_at).format('DD.MM.YYYY HH:mm')}
      </CTableDataCell>
      <CTableDataCell>
        {task.title}
      </CTableDataCell>
      <CTableDataCell>
        {cutBySpaceAboveSpecificLength(task.description, 60)}
      </CTableDataCell>
      <CTableDataCell>
      {userNameFormation(task.author)}
      </CTableDataCell>
      <CTableDataCell className="text-center">
        <CButtonGroup size="sm" style={{display: 'flex', gap: '5px'}}>
          <CButton size="sm" color="success" className="default-table__action-btn"
              onClick={() => openModalsAndAddTaskHandler(task, setVisible, setActiveIdTask, setActiveTask)}
          >
              <i className="fa fa-eye color-white" aria-hidden="true"/>
            </CButton>
            <CButton 
              size="sm" 
              color="warning" 
              className="default-table__action-btn"
              onClick={() => openModalsAndAddTaskHandler(task, setVisibleEdit, setActiveIdTask, setActiveTask)}
              disabled={me?.id !== task?.responsible_executor?.id && me?.id !== task?.author?.id}
            >
              <i className="fa fa-arrows-v color-white" aria-hidden="true"></i>
            </CButton>
            <CButton 
              disabled={me?.id !== task?.author?.id}
              size="sm" 
              color="info" 
              className="default-table__action-btn"
              onClick={() =>
                  navigateToPathAndIdHandler('/assignments/assignments-main/create-edit-assignment',
                      me?.id === task.author?.id ? String(task.id) : '', task.status, navigate)
              }
            >
                  <i className="fa fa-pencil-square-o color-white"/>
                </CButton>
            <CButton
                disabled={me?.id !== task?.author?.id}
                size="sm"
                color="danger"
                className="default-table__action-btn"
                onClick={() => openModalsAndAddTaskHandler(task, setRemButton, setActiveIdTask, setActiveTask)}
            >
              <i className="fa fa-times color-white" aria-hidden="true"/>
            </CButton>
        </CButtonGroup>
      </CTableDataCell>
    </CTableRow>
    );
  });

  return (
    <div>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('dragNdrop.done')}</h1>
      </div>

      <SortAndFilter
        defaultSettings={filterSettings}
        onChangeSettings={setFilterSettings}
      />

      <CTable bordered striped className="default-table desktop-only">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => setFilterHandler('created_at')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('assignmentsPage.date')}</span>
                <i
                  className="fa fa-sort"
                  aria-hidden="true"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => setFilterHandler('title')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('assignmentsPage.title')}</span>
                <i
                  className="fa fa-sort"
                  aria-hidden="true"
                />
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => setFilterHandler('description')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('assignmentsPage.description')}</span>
                <i
                  className="fa fa-sort"
                  aria-hidden="true"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => setFilterHandler('author')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('assignmentsPage.author')}</span>
                <i
                  className="fa fa-sort"
                  aria-hidden="true"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
              {t('assignmentsPage.actions')}
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {approvalTasks}
        </CTableBody>
      </CTable>

      <div className="task-card-mobile__list">
        {doneState?.map((task, idx: number) => (
          <TaskCardMobile key={'task' + idx}
                          task={task}
                          onClick={() => openModalsAndAddTaskHandler(task, setVisible, setActiveIdTask, setActiveTask)}
          />
        ))}
      </div>

      <DefaultModal
        visible={visible}
        setVisible={setVisible}
        title={activeTask?.title}
        size="xl"
        type="info"
      >
        {activeTask &&
          <div className='board-block_modal'>
            <div className='board-block_modal_content'>
              {/*<h3 className='board-block_modal_content_title'>{activeTask?.title}</h3>*/}
              <div className="board-block_modal_content-top">
                <div className="board-block_modal_content-top-left">
                  <h5 className='board-block_modal_content_description-title'>{t('assignmentsPage.description')}</h5>
                  <p className='board-block_modal_content_description'>{activeTask?.description}</p>
                </div>

                <div className="board-block_modal_content-top-right">
                  <h4 className='board-block_modal_content_author'>
                                        <span style={{
                                          color: '#000',
                                          fontSize: '12px'
                                        }}>{t('assignmentsPage.author')}: </span>
                    {activeTask.author.id === me?.id ? t('assignmentsPage.you') : activeTask?.author?.username}
                  </h4>
                  <h4 className='board-block_modal_content_author'>
                                        <span style={{
                                          color: '#000',
                                          fontSize: '12px'
                                        }}>{t('assignmentsPage.responsibleExecutor')}: </span>
                    {activeTask?.responsible_executor?.username}
                  </h4>

                  <div className='board-block_modal_content_date'>
                    {/* дата создания */}
                    <div>
                      <b>{t('assignmentsPage.create_at')}</b>
                      <i
                        className='board-block_modal_content_deadline'>{dayjs(activeTask?.created_at).format('DD-MM-YYYY HH:mm:ss')}</i>
                    </div>
                    {/**/}
                    {/* Сроки исполнения */}
                    {activeTask?.datetime_deadline
                      ? <div>
                        <b>{t('assignmentsPage.deadline')}</b>
                        <span>{t('assignmentsPage.to')}</span>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(activeTask?.datetime_deadline).format('DD-MM-YYYY HH:mm:ss')}
                        </i>
                      </div>
                      : null
                    }
                    {/**/}
                    {/* Дата принятия в работу */}
                    {activeTask?.datetime_employment
                      ? <div>
                        <b>{t('assignmentsPage.datetime_employment')}</b>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(activeTask?.datetime_employment).format('DD-MM-YYYY HH:mm:ss')}
                        </i>
                      </div>
                      : null
                    }

                    {/* дата исполнения */}
                    {activeTask?.datetime_actual_execution ?
                      <div>
                        <b>{t('assignmentsPage.actual_execution')}</b>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(activeTask?.datetime_actual_execution).format('DD-MM-YYYY HH:mm:ss')}
                        </i>
                      </div>
                      : null
                    }

                    {activeTask?.datetime_actual_approval ?
                      <div>
                        <b>{t('assignmentsPage.datetime_actual_approval')}</b>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(activeTask?.datetime_actual_approval).format('DD-MM-YYYY HH:mm:ss')}
                        </i>
                      </div>
                      : null
                    }

                    {/*<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>*/}
                    <div style={{display: 'flex', gap: '10px'}}>
                      <b>{t('assignmentsPage.priority')}</b>
                      <PriorityComponent priority={activeTask?.priority}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
            {activeTask?.file_assignments?.length ?
              <div>
                <h5 className='board-block_modal_content_description-title'>{t('assignmentsPage.imgDesc')}</h5>
                <div className="board-block_modal_content_files">
                  {activeTask?.file_assignments && activeTask?.file_assignments.map((item: any) => {
                    return (
                      <FileComponents
                        key={item.id}
                        extension={extension}
                        icon={icon.cilPaperclip}
                        item={item}
                        getDataWithQueryHandler={getDataWithQueryHandler}
                      />
                    )
                  })}
                </div>
              </div>
              : null}
            <div className='board-block_modal_comments'>
              <CommentsComponent taskID={activeTask?.id}/>
            </div>
          </div>
        }
      </DefaultModal>
      <DefaultModal
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        title={t('assignmentsPage.editStatus')}
        type="info"
        // cancelButton={t('cancel')}
        onOk={changeTaskHandler}
        okDisabled={permission === null || permission}
        // error={error}
      >

        <div>
          <select
            className="form-select form-select-lg mb-3"
            aria-label=".form-select-lg example"
            onChange={(e: SyntheticEvent<HTMLSelectElement, Event>) =>
              chooseStatusHandler(e, activeTask, setPermission, setNewStatus, me)}
            defaultValue={activeTask && activeTask?.status}
          >

            <option value={activeTask?.status} disabled>{t(`assignmentsPage.option`)}</option>
            {Statuses.map(status => (
              <React.Fragment key={status}>
                {status !== activeTask?.status
                  ? <option value={status}>{t(`dragNdrop.${status}`)}</option>
                  : null
                }
              </React.Fragment>
            ))}
          </select>
          {permission !== null && false && <span style={{color: 'red'}}>{t('assignmentsPage.permission denied')}</span>}
        </div>

      </DefaultModal>

      <DefaultModal
        visible={remButton}
        setVisible={setRemButton}
        title={t('assignmentsPage.remove')}
        size="lg"
        type="danger"
        onOk={() => removeAssignmentHandler(activeTask?.id && activeTask?.id)}
        // error={error}
      >
        {activeTask && <strong>{activeTask.title}</strong>}
      </DefaultModal>
    </div>
  )
}

export default Done;