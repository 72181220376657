const origin = window.location.origin.includes('localhost') ? 'https://iamsui.yorc.org' : window.location.origin;

// const baseUrl = 'http://ais.mes.gov.kg:14323';
const baseUrl = origin;
const webSocketSecure = `${origin.replace("https", "wss").replace("http", "ws").replace("/api","")}`;

const config = {
  baseUrl,
  apiUrl: baseUrl + '/api',
  storageUrl: baseUrl + '/api/storage/',
  webSocketSecure,
  WS_URL_ES_NOTIFY: webSocketSecure + '/ws/emergency-messages/',
  WS_URL_PERSONAL: webSocketSecure + '/ws/personalchat/',
  WS_URL_GROUP: webSocketSecure + '/ws/groupchat/',
  WS_URL_PERSONAL_NOTIFY: webSocketSecure + '/ws/personalchat/notify/',
  WS_URL_GROUP_NOTIFY: webSocketSecure + '/ws/groupchat/notify/',
  WS_URL_ES_FEED: webSocketSecure + '/ws/emergency/feed/',
  WS_URL_ES_CHAT: webSocketSecure + '/ws/comment/with-emergency/'
};

export const ASSIGNMENTS_FILTER_SETTINGS = 'assignmentsFilterSettings';

export default config;
