import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CBadge } from '@coreui/react';

interface IAppSidebarNav {
    items: any;
}

export const AppSidebarNav: React.FC<IAppSidebarNav> = ({ items }) => {
    const location = useLocation();
    const [openGroupIndex, setOpenGroupIndex] = useState<string | null>(null);

    useEffect(() => {
        // Retrieve the open group index from localStorage on component mount
        const savedGroupIndex = localStorage.getItem('openGroupIndex');
        if (savedGroupIndex) {
            setOpenGroupIndex(savedGroupIndex);
        }
    }, []);

    const handleGroupToggle = (index: string) => {
        // Toggle the current group index
        if (openGroupIndex === index) {
            setOpenGroupIndex(null); // Close if the same group is clicked
            localStorage.removeItem('openGroupIndex'); // Clear the storage if closing
        } else {
            setOpenGroupIndex(index);
            localStorage.setItem('openGroupIndex', index); // Save new index
        }
    };

    const navLink = (name: string, icon: any, badge: any) => (
        <>
            {icon && icon}
            {name && name}
            {badge && (
                <CBadge color={badge.color} className="ms-auto">
                    {badge.text}
                </CBadge>
            )}
        </>
    );

    const navItem = (item: any, index: number) => {
        const {component, name, badge, icon, ...rest} = item
        const Component = component

        if (item.to === location.pathname) {
            // if (location.pathname.includes(item.to)) {
            return (
                <div style={{background: '#EB7D3C', margin: '0 0.375rem 0 0', borderRadius: '0 6px 6px 0'}}
                     key={index}
                     className={item.className || ''}
                >
                    <Component
                        {...(rest.to &&
                            !rest.items && {
                                component: NavLink,
                            })}
                        key={index}
                        {...rest}
                    >
                        {navLink(name, icon, badge)}
                    </Component>
                </div>
            )
        } else {
            return (
                <Component
                    {...(rest.to &&
                        !rest.items && {
                            component: NavLink,
                        })}
                    key={index}
                    {...rest}
                >
                    {navLink(name, icon, badge)}
                </Component>
            )
        }

    }

    // const navItem = (item: any, index: number) => {
    //     const { component, name, badge, icon, ...rest } = item;
    //     const Component = component;
    //
    //     const isActive = item.to === location.pathname;
    //
    //     return (
    //         <Component
    //             {...(rest.to && !rest.items ? { component: NavLink } : {})}
    //             key={index}
    //             {...rest}
    //         >
    //             {navLink(name, icon, badge)}
    //             {isActive && (
    //                 <div
    //                     style={{
    //                         background: '#EB7D3C',
    //                         margin: '0 0.375rem 0 0',
    //                         borderRadius: '0 6px 6px 0',
    //                     }}
    //                 />
    //             )}
    //         </Component>
    //     );
    // };

    const navGroup = (item: any, index: number) => {
        const { component, name, icon, to, items, ...rest } = item;
        const Component = component;

        const isVisible = openGroupIndex === String(index) || location.pathname.startsWith(to);

        return (
            <div key={index}>
                <Component
                    idx={String(index)}
                    //@ts-ignore
                    toggler={navLink(name, icon)}
                    visible={isVisible}
                    onClick={() => handleGroupToggle(String(index))} // Handle toggle
                    {...rest}
                />

                {isVisible && items?.map((subItem: any, subIndex: number) =>
                    subItem.items ? navGroup(subItem, subIndex) : navItem(subItem, subIndex)
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            {items &&
                items.map((item: any, index: number) =>
                    item.items ? navGroup(item, index) : navItem(item, index)
                )}
        </React.Fragment>
    );
};

