import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IVersionHistory} from "../../interfaces/IVersionHistory";

export const versionHistoryApi = createApi({
  reducerPath: 'logs/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  endpoints: build => ({
    getVersionHistoryList: build.query<IVersionHistory[], string | void>({
      query: (query) => {
        return {
          url: `/version-history/${query || ''}`
        }
      }
    }),
    getCurrentVersion: build.query<IVersionHistory, void>({
      query: () => ({
        url: '/version-history/?version=last'
      })
    }),
  })
})

export const {
  useGetVersionHistoryListQuery,
  useLazyGetVersionHistoryListQuery,
  useGetCurrentVersionQuery
} = versionHistoryApi;
