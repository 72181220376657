import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import HtmlReactParser from "html-react-parser";

import makeQuery from "../../../functions/makeQuery";
import { useLazyGetVersionHistoryListQuery } from "../../../store/versionHistory/versionHistory.api";

const VersionHistory = () => {
  const {t, i18n} = useTranslation();

  const [getVersionList, {data, isLoading}] = useLazyGetVersionHistoryListQuery();

  useEffect(() => {
    getVersionList(makeQuery.stringify({lang: i18n.language}));
  }, [i18n.language]);

  return (
    <div className={isLoading ? 'page-loading' : ''}>
      <div className="pb-3 mb-2 title-divider flex">
        <h1>{t('nav.Version history')}</h1>
      </div>

      <div className="version-history__list">
        {data?.map(el => (
          <div key={'version' + el.version} className="version-history__card">
            <div className="version-history__card-header">
              <div className="version-history__date">
                {dayjs(el.version_date).format('DD.MM.YYYY')}
              </div>
              <div className="version-history__version">
                v {el.version}
              </div>
              <div className="version-history__title">
                {el.title}
              </div>
            </div>
            <div className="version-history__description">
              {HtmlReactParser(el.description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VersionHistory;
