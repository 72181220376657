import BoardSectionList from '../dragAndDrop/components/BoardSectionList';
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const AssignmentsMain = () => {
  const navigate = useNavigate();

  useEffect(() => {
    onResizeHandler();
    window.addEventListener('resize', onResizeHandler);
    return () => window.removeEventListener('resize', onResizeHandler);
  }, []);

  const onResizeHandler = () => {
    if (window.innerWidth <= 800) navigate('/assignments/generated', {replace: true});
  };

  return (
    <BoardSectionList />
  );
};

export default AssignmentsMain;
