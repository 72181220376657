import {CSidebarNav} from "@coreui/react";
import React, {useEffect} from "react";
import {useLazyGetInfoUserQuery} from "../store/account/account.api";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import avatar from "../assets/images/avatars/avatar.svg";

const Profile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [getMe, {isError: errorGetMe,  isLoading: loadingGetMe, data: me}] = useLazyGetInfoUserQuery();

    useEffect(() => {
        getMe();
    }, []);

    return (
        <CSidebarNav className='sidebar-profile'>
            {me ?
                <NavLink to={'/profile'} className="profile-block">
                    <div className="profile-block-img avatar">
                        <img src={me.avatar ? me.avatar :avatar} alt="avatar" className='avatar-img'/>
                    </div>
                    <div className="profile-block-info">
                        <p className="profile-info-username">{me.last_name} {me.first_name} {me.patronymic}</p>
                        <p className="profile-info-username">{me?.position?.name} </p>
                    </div>
                </NavLink>
                : null}
        </CSidebarNav>
    )
}

export default Profile;
