import {configureStore} from "@reduxjs/toolkit";

import {rolesApi} from "./roles/roles.api";
import btnSideBarSlice from "./buttonsApp/BtnSideBarSlice";
import {assignmentsApi} from "./assignments/assignments.api";
import btnSlice from "./buttonsApp/ButtonsSlice";
import {usersApi} from "./users/users.api";
import {readCurrentUserApi} from "./account/account.api";
import {authApi} from "./auth/auth.api";
import {districtsApi} from "./districts/districts.api";
import {divisionsApi} from "./divisions/divisions.api";
import {docTypesApi} from "./docTypes/docTypes.api";
import {documentsApi} from "./documents/documents.api";
import {emergencyTypesApi} from "./emergencyTypes/emergencyTypes.api";
import {emergencyFilesApi} from "./emergencyFiles/emergencyFiles.api";
import {emergenciesApi} from "./emergencies/emergencies.api";
import {generalInfoEmergenciesApi} from "./generalInfoEmergency/generalInfoEmergency.api";
import {geoCategoriesApi} from "./geoCategories/geoCategories.api";
import {geoIconsApi} from "./geoIcons/geoIcons.api";
import {geoObjectsApi} from "./geoObjects/geoObjects.api";
import {geoObjImagesApi} from "./geoObjImages/geoObjImages.api";
import {geoTypesApi} from "./geoTypes/geoTypes.api";
import {groupApi} from "./group/group.api";
import {logsApi} from "./logs/logs.api";
import {permissionsApi} from "./permissions/permissions.api";
import {regionsApi} from "./regions/regions.api";
import {servicesApi} from "./services/services.api";
import {videoStreamsApi} from "./videoStreams/videoStreams.api";
import {countiesApi} from "./counties/counties.api";
import {localitiesApi} from "./localities/localities.api";
import {affectedPopulationApi} from "./affectedPopulation/affectedPopulation.api";
import {esFieldsApi} from "./esFields/esFields";
import {analyticsApi} from "./analytics/analytics.api";
import esFeedSlice from "./esFeed/esFeedSlice";
import {esFormApi} from "./esForm/esForm";
import {chatsApi} from "./chats/chats.api";
import {dailyInfoApi} from "./dailyInfo/dailyInfo.api";
import {emergencyMessagesApi} from "./emergencyMessages/emergencyMessages.api";
import {mainPagesApi} from "./mainPage/mainPage.api";
import {emergencyChatApi} from "./emergencyChat/emergencyChat.api";
import {urgentNotificationsApi} from "./urgentNotifications/urgentNotifications.api";
import {versionHistoryApi} from "./versionHistory/versionHistory.api";

export const configStore = configureStore({
    reducer: {
        [rolesApi.reducerPath]: rolesApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [readCurrentUserApi.reducerPath]: readCurrentUserApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [countiesApi.reducerPath]: countiesApi.reducer,
        [districtsApi.reducerPath]: districtsApi.reducer,
        [divisionsApi.reducerPath]: divisionsApi.reducer,
        [docTypesApi.reducerPath]: docTypesApi.reducer,
        [documentsApi.reducerPath]: documentsApi.reducer,
        [emergencyTypesApi.reducerPath]: emergencyTypesApi.reducer,
        [emergencyFilesApi.reducerPath]: emergencyFilesApi.reducer,
        [emergenciesApi.reducerPath]: emergenciesApi.reducer,
        [generalInfoEmergenciesApi.reducerPath]: generalInfoEmergenciesApi.reducer,
        [geoCategoriesApi.reducerPath]: geoCategoriesApi.reducer,
        [geoIconsApi.reducerPath]: geoIconsApi.reducer,
        [geoObjectsApi.reducerPath]: geoObjectsApi.reducer,
        [geoObjImagesApi.reducerPath]: geoObjImagesApi.reducer,
        [geoTypesApi.reducerPath]: geoTypesApi.reducer,
        [groupApi.reducerPath]: groupApi.reducer,
        [localitiesApi.reducerPath]: localitiesApi.reducer,
        [logsApi.reducerPath]: logsApi.reducer,
        [permissionsApi.reducerPath]: permissionsApi.reducer,
        [regionsApi.reducerPath]: regionsApi.reducer,
        [servicesApi.reducerPath]: servicesApi.reducer,
        [videoStreamsApi.reducerPath]: videoStreamsApi.reducer,
        [affectedPopulationApi.reducerPath]: affectedPopulationApi.reducer,
        [esFieldsApi.reducerPath]: esFieldsApi.reducer,
        [analyticsApi.reducerPath]: analyticsApi.reducer,
        [esFormApi.reducerPath]: esFormApi.reducer,
        [assignmentsApi.reducerPath]: assignmentsApi.reducer,
        [chatsApi.reducerPath]: chatsApi.reducer,
        [dailyInfoApi.reducerPath]: dailyInfoApi.reducer,
        [emergencyMessagesApi.reducerPath]: emergencyMessagesApi.reducer,
        [emergencyChatApi.reducerPath]: emergencyChatApi.reducer,
        [mainPagesApi.reducerPath]: mainPagesApi.reducer,
        [urgentNotificationsApi.reducerPath]: urgentNotificationsApi.reducer,
        [versionHistoryApi.reducerPath]: versionHistoryApi.reducer,
        toggleBtnApp: btnSlice,
        toggleBtnSidebar: btnSideBarSlice,
        esFeedReducer: esFeedSlice
    },
    // @ts-ignore
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: true,
        immutableCheck: true,
    }).concat(
        [
            rolesApi.middleware,
            videoStreamsApi.middleware,
            servicesApi.middleware,
            regionsApi.middleware,
            districtsApi.middleware,
            divisionsApi.middleware,
            countiesApi.middleware,
            localitiesApi.middleware,
            permissionsApi.middleware,
            logsApi.middleware,
            usersApi.middleware,
            readCurrentUserApi.middleware,
            authApi.middleware,
            docTypesApi.middleware,
            documentsApi.middleware,
            emergencyTypesApi.middleware,
            emergencyFilesApi.middleware,
            emergenciesApi.middleware,
            generalInfoEmergenciesApi.middleware,
            geoCategoriesApi.middleware,
            geoIconsApi.middleware,
            geoObjectsApi.middleware,
            geoObjImagesApi.middleware,
            geoTypesApi.middleware,
            groupApi.middleware,
            affectedPopulationApi.middleware,
            esFieldsApi.middleware,
            analyticsApi.middleware,
            esFormApi.middleware,
            assignmentsApi.middleware,
            chatsApi.middleware,
            dailyInfoApi.middleware,
            emergencyMessagesApi.middleware,
            mainPagesApi.middleware,
            emergencyChatApi.middleware,
            urgentNotificationsApi.middleware,
            versionHistoryApi.middleware
        ]
    )
})

export type RootState = ReturnType<typeof configStore.getState>
export type AppDispatch = typeof configStore.dispatch
