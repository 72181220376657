import {IEmergencyState, IMainState} from "../../../interfaces/IEmergencyState";

export const initialMainState: IMainState = {
  type_emergency: 0,
  type_report: '',
  date_emergency: new Date(),
  region: '',
  district: '',
  county: '',
  locality: '',
  title: '',
  description: '',
  material_damage: 0,
  human_casualties: 0,
  number_of_victims: 0,
  latitude: undefined,
  longitude: undefined,
  status: 'in processing',
  late_information: false,
  field_emergency: []
}

export const initialEmergencyState: IEmergencyState = {
  id: 0,
  emergency: 0,
  // Всего погибших и без вести пропавших
  qty_dead_and_missing: 0,
  men_dead_and_missing: 0,
  zero_five_men_dead_and_missing: 0,
  six_seventeen_men_dead_and_missing: 0,
  eighteen_fifty_nine_men_dead_and_missing: 0,
  sixty_and_older_men_dead_and_missing: 0,
  women_dead_and_missing: 0,
  zero_five_women_dead_and_missing: 0,
  six_seventeen_women_dead_and_missing: 0,
  eighteen_fifty_nine_women_dead_and_missing: 0,
  sixty_and_older_women_dead_and_missing: 0,
  // Всего погибших
  qty_dead: 0,
  men_dead: 0,
  zero_five_men_dead: 0,
  six_seventeen_men_dead: 0,
  eighteen_fifty_nine_men_dead: 0,
  sixty_and_older_men_dead: 0,
  women_dead: 0,
  zero_five_women_dead: 0,
  six_seventeen_women_dead: 0,
  eighteen_fifty_nine_women_dead: 0,
  sixty_and_older_women_dead: 0,
  // Всего пропавших без вести
  qty_missing: 0,
  men_missing: 0,
  zero_five_men_missing: 0,
  six_seventeen_men_missing: 0,
  eighteen_fifty_nine_men_missing: 0,
  sixty_and_older_men_missing: 0,
  women_missing: 0,
  zero_five_women_missing: 0,
  six_seventeen_women_missing: 0,
  eighteen_fifty_nine_women_missing: 0,
  sixty_and_older_women_missing: 0,
  // Всего пострадавших
  qty_affected: 0,
  men_affected: 0,
  zero_five_men_affected: 0,
  six_seventeen_men_affected: 0,
  eighteen_fifty_nine_men_affected: 0,
  sixty_and_older_men_affected: 0,
  women_affected: 0,
  zero_five_women_affected: 0,
  six_seventeen_women_affected: 0,
  eighteen_fifty_nine_women_affected: 0,
  sixty_and_older_women_affected: 0,
  // Всего травмированных, но не госпитализированных
  qty_injured_but_not_hospitalized: 0,
  men_injured_but_not_hospitalized: 0,
  zero_five_men_injured_but_not_hospitalized: 0,
  six_seventeen_men_injured_but_not_hospitalized: 0,
  eighteen_fifty_nine_men_injured_but_not_hospitalized: 0,
  sixty_and_older_men_injured_but_not_hospitalized: 0,
  women_injured_but_not_hospitalized: 0,
  zero_five_women_injured_but_not_hospitalized: 0,
  six_seventeen_women_injured_but_not_hospitalized: 0,
  eighteen_fifty_nine_women_injured_but_not_hospitalized: 0,
  sixty_and_older_women_injured_but_not_hospitalized: 0,
  // Всего травмированных, госпитализированных
  qty_injured_hospitalized: 0,
  men_injured_hospitalized: 0,
  zero_five_men_injured_hospitalized: 0,
  six_seventeen_men_injured_hospitalized: 0,
  eighteen_fifty_nine_men_injured_hospitalized: 0,
  sixty_and_older_men_injured_hospitalized: 0,
  women_injured_hospitalized: 0,
  zero_five_women_injured_hospitalized: 0,
  six_seventeen_women_injured_hospitalized: 0,
  eighteen_fifty_nine_women_injured_hospitalized: 0,
  sixty_and_older_women_injured_hospitalized: 0,
  // Всего покинувших место жительства
  qty_who_left_their_place_of_residence: 0,
  men_who_left_their_place_of_residence: 0,
  zero_five_men_who_left_their_place_of_residence: 0,
  six_seventeen_men_who_left_their_place_of_residence: 0,
  eighteen_fifty_nine_men_who_left_their_place_of_residence: 0,
  sixty_and_older_men_who_left_their_place_of_residence: 0,
  women_who_left_their_place_of_residence: 0,
  zero_five_women_who_left_their_place_of_residence: 0,
  six_seventeen_women_who_left_their_place_of_residence: 0,
  eighteen_fifty_nine_women_who_left_their_place_of_residence: 0,
  sixty_and_older_women_who_left_their_place_of_residence: 0,
  // Временно оставивших место жительства
  qty_temporarily_left_the_place_of_residence: 0,
  men_temporarily_left_the_place_of_residence: 0,
  zero_five_men_temporarily_left_the_place_of_residence: 0,
  six_seventeen_men_temporarily_left_the_place_of_residence: 0,
  eighteen_fifty_nine_men_temporarily_left_the_place_of_residence: 0,
  sixty_and_older_men_who_temporarily_left_the_place_of_residence: 0,
  women_temporarily_left_the_place_of_residence: 0,
  zero_five_women_temporarily_left_the_place_of_residence: 0,
  six_seventeen_women_temporarily_left_the_place_of_residence: 0,
  eighteen_fifty_nine_women_temporarily_left_the_place_of_residence: 0,
  sixty_and_older_women_who_temporarily_left_the_place_of_residence: 0,
  // Отселенные - сменивших постоянное место жительства
  qty_resettled: 0,
  men_resettled: 0,
  zero_five_men_resettled: 0,
  six_seventeen_men_resettled: 0,
  eighteen_fifty_nine_men_resettled: 0,
  sixty_and_older_men_who_resettled: 0,
  women_resettled: 0,
  zero_five_women_resettled: 0,
  six_seventeen_women_resettled: 0,
  eighteen_fifty_nine_women_resettled: 0,
  sixty_and_older_women_who_resettled: 0,
  // Гуманитарная помощь
  total_recipients_of_food_and_humanitarian_aid: 0,
  // Пострадавшие домохозяйства
  qty_households_affected_by_the_emergency: 0,
  resettled: 0,
  registered_in_local_government_records_as_low_income_families: 0,
  qty_households_result_died: 0,
  // Жилые дома
  qty_damaged_houses: 0,
  qty_individual_houses: 0,
  first_third_degre_of_damage_individual_houses: 0,
  fourth_fifth_degre_of_damage_individual_houses: 0,
  qty_apartment_buildings: 0,
  first_third_degre_of_damage_apartment_buildings: 0,
  fourth_fifth_degre_of_damage_apartment_buildings: 0,
  general_damage_to_residential_buildings: 0,
  cost_of_damaged_and_destroyed_property: 0,
  // Объекты здравоохранения
  qty_health_facilities: 0,
  first_third_degre_of_damage_health_facilities: 0,
  fourth_fifth_degre_of_damage_health_facilities: 0,
  total_number_of_outpatient_facilities_affected: 0,
  total_number_of_affected_hospitals: 0,
  number_of_affected_beds_in_outpatient_facilities: 0,
  number_of_affected_beds_in_inpatient_facilities: 0,
  general_damage_to_healthcare_buildings_and_facilities: 0,
  cost_of_damaged_and_destroyed_property_and_medical_equipment: 0,
  number_of_medical_institutions_located_in_potentially_dangerous_areas: 0,
  // Объекты образования
  qty_objects_of_education: 0,
  preschool: 0,
  first_third_degre_of_damage_preschool: 0,
  fourth_fifth_degre_of_damage_preschool: 0,
  general_educational_institutions: 0,
  first_third_degre_of_damage_general_educational_institutions: 0,
  fourth_fifth_degre_of_damage_general_educational_institutions: 0,
  secondary_special_and_higher_educational_institutions: 0,
  first_third_degre_of_damage_secondary_special_and_higher_educational_institutions: 0,
  fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions: 0,
  general_damage_to_educational_buildings_and_structures_secondary_special_and_higher_educational_institutions: 0,
  cost_of_damaged_and_destroyed_property_and_equipment_secondary_special_and_higher_educational_institutions: 0,
  number_of_educational_facilities_located_in_potentially_hazardous_areas_secondary_special_and_higher_educational_institutions: 0,
  // Объекты культуры
  qty_cultural_objects: 0,
  first_third_degre_of_damage_cultural_objects: 0,
  fourth_fifth_degre_of_damage_cultural_objects: 0,
  general_damage_to_cultural_buildings_and_structures_cultural_objects: 0,
  cost_of_damaged_and_destroyed_property_and_equipment_cultural_objects: 0,
  number_of_educational_facilities_located_in_potentially_hazardous_areas_cultural_objects: 0,
  // Здания административного назначения
  qty_administrative_buildings: 0,
  first_third_degre_of_damage_administrative_buildings: 0,
  fourth_fifth_degre_of_damage_secondary_administrative_buildings: 0,
  general_damage_to_administrative_buildings: 0,
  cost_of_damaged_and_destroyed_property_and_equipment_administrative_buildings: 0,
  number_of_educational_facilities_located_in_potentially_hazardous_areas_administrative_buildings: 0,
  // Объекты дорожного хозяйства
  qty_road_facilities: 0,
  roads_of_republican_significance: 0,
  on_farm_roads: 0,
  bridges_on_national_roads: 0,
  bridges_on_roads_of_on_farm_significance: 0,
  railways: 0,
  tunnels_galleries: 0,
  general_damage_to_road_facilities: 0,
  // Объекты энергетики
  energy_facilities: 0,
  power_stations: 0,
  transformer_substations: 0,
  power_transmission_towers: 0,
  power_lines: 0,
  general_damage_to_energy_facilities: 0,
  // Объекты связи
  communication_objects: 0,
  communication_lines: 0,
  communication_line_poles: 0,
  equipment_and_devices: 0,
  general_damage_to_communication_facilities: 0,
  // Гидротехнические сооружения
  hydraulic_structures: 0,
  hydroelectric_power_plant: 0,
  dam: 0,
  reservoir: 0,
  other: 0,
  general_damage_to_hydraulic_structures: 0,
  // Объекты коммунального хозяйства
  utilities_facilities: 0,
  water_intake_facilities_for_drinking_purposes: 0,
  water_lines: 0,
  sewer_lines: 0,
  gas_distribution_stations: 0,
  gas_pipelines: 0,
  boiler_houses: 0,
  heat_pipes: 0,
  treatment_facilities: 0,
  general_damage_to_public_utilities: 0,
  // Объекты ирригации
  irrigation_objects: 0,
  water_intake_facilities_for_irrigation_purposes: 0,
  water_distribution_facilities: 0,
  reservoirs_irrigation: 0,
  irrigation_networks_of_on_farm_significance_destroyed: 0,
  destruction_of_irrigation_networks_of_off_farm_significance: 0,
  destroyed_collector_drainage_networks: 0,
  general_damage_to_irrigation_facilities: 0,
  // Промышленные и коммерческие объекты
  industrial_and_commercial_facilities: 0,
  state_owned_industrial: 0,
  in_municipal_ownership_industrial: 0,
  in_private_ownership_industrial: 0,
  general_damage_to_industrial_and_commercial_facilities: 0,
  // Техника, передвижные устройства и оборудование
  the_number_of_damaged_machinery_mobile_devices_and_equipment: 0,
  state_owned_devices: 0,
  in_municipal_ownership_devices: 0,
  in_private_ownership_devices: 0,
  general_damage_to_machinery_mobile_devices_and_equipment: 0,
  // Сельское хозяйство и лесное хозяйство
  agriculture_and_forestry: 0,
  irrigated_arable_land: 0,
  non_irrigated_arable_land: 0,
  pastures: 0,
  forest_land: 0,
  general_damage_to_agricultural_land: 0,
  total_area_of_dead_crops: 0,
  cereals: 0,
  roots: 0,
  cotton: 0,
  vegetables: 0,
  beans_and_other_legumes: 0,
  perennial_plantations: 0,
  general_damage_to_crops: 0,
  total_number_of_dead_livestock_and_poultry: 0,
  cattle: 0,
  mrs: 0,
  horses: 0,
  birds: 0,
  general_damage_to_livestock_and_poultry: 0,
  // Новые земельные участки выделеные домохозяйствам, отселенным из опасных участков для строительства индивидуального жилья
  number_of_new_and_plots_allocated_to_households_resettled_from_hazardous_areas_for_the_construction_of_individual_housing: 0,
  number_of_households: 0,
  number_of_community_members: 0,
  number_of_allotted_apartments_and_or_houses: 0,
  number_of_families: 0,
  number_of_family_members: 0,
  // Объекты социальной защиты
  objects_of_social_protection: 0,
  social_stationary_institutions: 0,
  first_third_degre_of_damage_social_stationary_institutions: 0,
  fourth_fifth_degre_of_damage_social_stationary_institutions: 0,
  semi_permanent_institutions: 0,
  first_third_degre_of_damage_semi_permanent_institutions: 0,
  fourth_fifth_degre_of_damage_semi_permanent_institutions: 0,
  general_damage_to_buildings_and_structures_of_social_protection_facilities: 0,
  cost_of_damaged_and_destroyed_property_and_equipment_objects_of_social_protection: 0,
  number_of_social_protection_facilities_located_in_potentially_dangerous_areas: 0,
  // Суммарный объем средств, направленных на ликвидацию последствий чрезвычайной ситуации (средства МЧС)
  the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency: 0,
  budgetary_funds_of_the_ministry_of_emergency_situations: 0,
  donor_funds: 0,
  // Суммарный объем средств, направленных на восстановление по источникам финансирования (данные органов МСУ и профильных министерств)
  the_total_amount_of_funds_allocated_for_restoration_by_funding_sources: 0,
  budget_resources_allocated_for_restoration_by_funding_sources: 0,
  donor_funds_allocated_for_restoration_by_funding_sources: 0,
  restoration_of_management_functions_and_provision_of_state_and_municipal_services: 0,
  budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services: 0,
  donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services: 0,
  resettlement_of_the_population_to_safe_areas: 0,
  budget_resources_resettlement_of_the_population_to_safe_areas: 0,
  donor_funds_resettlement_of_the_population_to_safe_areas: 0,
  restoration_of_the_vital_activity_of_the_economy: 0,
  budget_resources_restoration_of_the_vital_activity_of_the_economy: 0,
  donor_funds_restoration_of_the_vital_activity_of_the_economy: 0,
  // Суммарный объем гуманитарной помощи
  total_volume_of_humanitarian_aid: 0,
}